.JobPage {
  min-width: 100vw;
  height: 100%;
  padding-right: 30px;
  padding-left: 30px;

  margin: auto;
  overflow-y: hidden;
  margin-top: 64px;
}

@media screen and (max-height: 750px) {
  .JobPage {
    overflow-y: scroll;
  }
}

.JobListHeader {
  top: 10px;
}

@media screen and (min-width: 1801px) {
  div.JobPage {
    padding-top: 50px;
  }
}
