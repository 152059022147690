.JobItem {
  margin: 10px;
  padding: 10px;

  display: flex;
  justify-content: space-between;
}

.JobDetails {
  padding-left: 10px;
  padding-right: 4px;
  flex: 1;
  min-width: 0;
  white-space: normal;
}
