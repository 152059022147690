.JobListContainer {
  background-color: #f8f8f8;
  width: 275px;
  min-width: 275px;
  height: calc(82vh - 64px);
  padding: 20px 10px;
  margin: 10px 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-height: 740px) {
  .JobListContainer {
    max-height: 70vh;
  }
}

.JobListContainer:first-child {
  margin-left: 10px;
}

.JobListContainer:last-child {
  margin-right: 10px;
}

.JobListHeader {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.JobItems {
  height: 100%;
  width: 100%;
  transition: background-color 0.2s ease;
  margin-top: 10px;
  overflow-y: auto;
  overflow: overlay;
}

.JobItems:not(:hover)::-webkit-scrollbar {
  display: none;
}
.JobItems:not(:hover) {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #c8c8c8;
}
