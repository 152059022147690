.NotePage {
  margin-top: 64px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NotePageHeader {
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
}

h5.NotePageTitle {
  margin-top: 20px;
  margin-right: 50px;
}

.SortContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.SortBy {
  display: flex;
  align-items: center;
}

.SearchBy {
  display: flex;
  align-items: center;
}

.AccordionSummary {
  display: flex;
  align-items: center;
}

div.NoteMain {
  padding: 10px;
  border-radius: 5px;
}
