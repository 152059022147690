div.NotepadModalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.NotepadModal {
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  outline: none;
  overflow: hidden;
}

.CloseButton {
  color: #000000;

  width: 20px;
  height: 20px;
  top: 25px;
  right: 25px;
}

div.NotepadMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  box-sizing: border-box;
}
