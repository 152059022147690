.CreateJobModalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CreateJobModal {
  background-color: #ffffff;
  max-width: 90vw;
  min-width: 50vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  outline: none;
}

.CloseButton {
  color: #000000;

  width: 20px;
  height: 20px;
  top: 25px;
  right: 25px;
}

.ModalMain {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 20px 0;
}

@media screen and (max-width: 300px) {
  div.FormContainer {
    width: 80vw;
  }

  div.ShortInputContainer {
    width: 80vw;
  }
}

.FormInstruction {
  padding-bottom: 10px;
}

.saveButton {
  align-self: flex-end;
}

.toggleCreateFormButton {
  margin-top: 10px;
}

.errorModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vw;
}

div.CalendarStyles {
  height: 60vh;
  width: 80vw;
}

@media screen and (max-height: 600px) {
  div.CalendarStyles {
    max-height: 350px;
    max-width: 650px;
  }
}

.SelectedEventModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.SelectedEventModal {
  min-width: 50vw;
  max-width: 80vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

@media screen and (max-width: 300px) {
  .EventHeader {
    font-size: 18px;
  }

  .Date {
    font-size: 14px;
  }

  .JobDescription {
    font-size: 14px;
  }
}
