.InactiveJobModalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.InactiveJobModal {
  background-color: #ffffff;
  max-width: 90vw;
  min-width: 50vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  outline: none;
}

.CloseButton {
  color: #000000;

  width: 20px;
  height: 20px;
  top: 25px;
  right: 25px;
}

svg.WarningIcon {
  font-size: 50px;
  margin-bottom: 10px;
}

.Prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

div.JobList {
  overflow: auto;
}

@media screen and (max-height: 700px) {
  div.JobList {
    height: 45vh;
  }

  div.InactiveJobModal {
    padding: 40px 30px;
  }
}

@media screen and (max-width: 300px) {
  div.JobList {
    height: 35vh;
  }
}
