div.InterviewingContainer {
  border: 1px solid #0000004b;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

div.QuestionsContainer {
  flex: 1;
}

.Questions {
  white-space: pre-wrap;
}

.Skills {
  color: #2d799d;
}

.DateTimePicker {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10px;
}
