div.BookedAppliedContainer {
  border: 1px solid #0000004b;
  border-radius: 10px;
  height: 100%;

  overflow: auto;
  display: flex;
  flex-direction: column;
}

/* @media screen and (max-height: 700px) {
  div.BookedAppliedContainer {
    height: 45vh;
  }
}

@media screen and (max-width: 350px) {
  div.BookedAppliedContainer {
    height: 35vh;
  }
} */

.JobDescription {
  flex: 1;
  white-space: pre-wrap;
}

.ToggleDetailButton {
  width: 100%;
  align-self: center;
  justify-content: center;
  padding: 10px 0;
}
