.SearchPageMain {
  max-width: 1500px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}

.SearchPageHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.FilterContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.JobTableHead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.TableLabel {
  font-weight: bold;
}

.JobRow:hover {
  cursor: pointer;
}
