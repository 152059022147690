.JobBoard {
  display: flex;
  overflow-x: auto;
}

.JobListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 1800px) {
  .JobListHeader {
    position: fixed;
    left: 30px;
    width: calc(100vw - 55px);
  }
}

@media screen and (max-height: 750px) {
  .JobListHeader {
    top: 10px;
  }
}

h5.JobListTitle {
  padding: 20px 0;
  margin-right: 10px;
}

.JobListContainer {
  max-width: fit-content;
  margin: 0 auto;
}
