.PageLoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;

  /****** center box
  width: 300px;
  height: 300px;
  border: solid 1px #aaa;
  ******/
}

body {
  background: #1d1d1d;
  height: 100%;
}

.pac-man {
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 5em;
  border-radius: 100em 100em 0 0;
  background: #f00;
  transform-origin: bottom;
  animation: eating-top 0.5s infinite;
}

.pac-man,
.pac-man::before {
  width: 70px;
  height: calc(70px / 2);
  background: #009688;
}

.pac-man::before {
  content: "";
  display: block;
  margin-top: calc(70px / 2);
  position: absolute;
  transform-origin: top;
  border-radius: 0 0 100em 100em;
  transform: rotate(80deg);
  animation: eating-bottom 0.5s infinite;
}

.pac-man::after {
  position: absolute;
  border-radius: 100em;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  margin-top: calc((70px / 2) - 10px);
  margin-left: calc((70px / 2) - 10px);
  transform-origin: center;
  animation: center 0.5s infinite, ball 0.5s -0.33s infinite linear;
}

@keyframes eating-top {
  0% {
    transform: rotate(-40deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-40deg);
  }
}

@keyframes eating-bottom {
  0% {
    transform: rotate(80deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(80deg);
  }
}

@keyframes center {
  0% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(40deg);
  }
}

@keyframes ball {
  0% {
    opacity: 0.7;
    box-shadow: 70px 0 0 0 #009688, 120px 0 0 0 #009688, 170px 0 0 0 #009688,
      220px 0 0 0 #009688;
  }
  100% {
    box-shadow: 20px 0 0 0 #009688, 70px 0 0 0 #009688, 120px 0 0 0 #009688,
      170px 0 0 0#009688;
  }
}
