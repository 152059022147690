.SearchBar {
  flex: 1;
}

@media screen and (min-width: 800px) {
  .SearchBar {
    flex: none;
    width: 300px;
  }
}
