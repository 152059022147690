div.InterviewedContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

div.ChecklistMain {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-height: 600px) {
  div.ChecklistMain {
    justify-content: flex-start;
  }
}

.ProgressMessage {
  align-self: center;
  font-weight: bold;

  display: flex;
  align-items: center;
  position: relative;
}

.CheckboxProgressBar {
  border-radius: 10px;
}

.Checklist {
  align-self: flex-start;
  margin-bottom: 20px;
}

div.NotepadMain {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 20px;
}

.NoteTextField {
  max-height: 100%;
  height: 80%;
}

.Skills {
  color: #2d799d;
  padding-top: 10px;
}
