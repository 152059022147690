.CreateJobModalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CreateJobModal {
  background-color: #ffffff;
  max-width: 90vw;
  min-width: 50vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  outline: none;
}

.CloseButton {
  color: #000000;

  width: 20px;
  height: 20px;
  top: 25px;
  right: 25px;
}

.ModalMain {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 20px 0;
}

.FormInstruction {
  padding: 15px 0;
}

@media screen and (max-width: 300px) {
  div.FormContainer {
    width: 80vw;
  }
  .JobForm {
    width: 80vw;
  }

  div.ShortInputContainer {
    width: 80vw;
  }

  .FormInstruction {
    padding: 0;
  }
}

.saveButton {
  align-self: flex-end;
}

.LoadingAnimation {
  max-height: 400px;
  height: 40vh;
  max-width: 400px;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleCreateFormButton {
  margin-top: 10px;
}

.errorModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.JobForm {
  display: flex;
  flex-direction: column;
}
