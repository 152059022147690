.AcceptedContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

div.AcceptedMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 350px) {
  div.AcceptedMain {
    justify-content: flex-start;
  }
}

h5.Congratulation {
  margin-bottom: 20px;
}

p.TopHeader {
  margin-bottom: 20px;
}

div.Suggestions {
  display: flex;
  flex-direction: column;
}

.Link {
  padding-bottom: 10px;
}
