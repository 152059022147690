.DateModalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DateModal {
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  overflow: auto;
  outline: none;
}

.Congratulation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  font-size: 1.4rem;
  font-weight: 800;
}

.CelebrationIcon {
  margin-bottom: 1rem;
}

.InterviewText {
  margin-top: 20px;
}

.DateTimePicker {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.DatePicker {
  margin-bottom: 10px;
}

.InterviewDate {
  margin-bottom: 10px;
}

.ButtonSubmit {
  margin-top: 10px;
}

.CelebrationIcon {
  height: 90px;
}
