.JobModalContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.JobModal {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  outline: none;
}

@media screen and (max-height: 700px) {
  div.JobModal {
    padding: 20px 30px;
  }
}

@media screen and (max-width: 350px) {
  div.JobModal {
    padding: 25px 18px;
  }
}

.JobHeader {
  margin-top: 10px;
}

.JobCompany {
  color: #2d799d;
}

.SubHeader {
  display: flex;
  flex: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.ModalMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  overflow: auto;
}

.ModalHeader {
  display: flex;
  flex-direction: column;

  padding-top: 25px;
}

.ModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Button {
  color: #000000;
}

.ButtonText {
  padding-right: 5px;
}

.PlaceHolderItem {
  width: 80%;
}
