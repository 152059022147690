.LegendContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 100vw;
}

.LegendItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 7px;
}

.LegendColor {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #0000003a;
}

.LegendLabel {
  font-size: 14px;
}

.LegendColumn1 {
  margin-right: 20px;
}
